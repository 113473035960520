@import "~@tabler/core/dist/css/tabler.min.css";
@import "~@tabler/core/dist/css/tabler-flags.min.css";
@import "~@tabler/core/dist/css/tabler-payments.min.css";
@import "~@tabler/core/dist/css/tabler-vendors.min.css";

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  color: #212529;
}

code {
  font-family: 'Fira Code', monospace;
  background: #e9ecef;
  padding: 2px 4px;
  border-radius: 4px;
}

.p-6 {padding: 0 !important;}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.sidebar-collapsed {
  width: 64px !important;
  min-width: 64px !important;
}
.sidebar-collapsed .navbar-brand,
.sidebar-collapsed .nav-link-title,
.sidebar-collapsed .text-muted,
.sidebar-collapsed hr {
  display: none !important;
}